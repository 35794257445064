<template>
  <div id="app" class="grid items-center justify-items-center">
    <img class="w-40" alt="Vue logo" src="./assets/logo.png">
  <HelloWorld class="text-center" msg="Welcome to Your Vue.js App"/>

  </div>
  
</template>

<script>
import HelloWorld from './components/HelloWorld.vue';
import './assets/tailwind.css';

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>


