<template>
  <div class="flex flex-col items-center justify-items-center">
    <h1 class="text-lime-300 p-6 font-bold text-4xl">{{ msg }}</h1>
    <p class="font-thin p-5">
      For a guide and recipes on how to configure / customize this project,<br>
      check out the
      <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.
    </p>
    <h3 class="text-center p-4 text-teal-500 font-bold text-xl bg-lime-50 rounded-full shadow-lg border-teal-500 border w-80">Installed CLI Plugins ! </h3>
    <ul class="bg-slate-100 border w-full m-4 rounded-lg shadow-lg">
      <li class="m-2 p-2 text-blue-400"><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li>
      <li class="m-2 p-2 text-blue-400"><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>
    </ul>
    <h3 class="p-4 text-teal-500 font-bold text-xl bg-lime-50 rounded-full shadow-lg border-teal-500 border w-80">Essential Links</h3>
    <ul class="bg-slate-100 border w-full m-4 rounded-lg shadow-lg">
      <li class="m-2 p-2 text-blue-400"><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>
      <li class="m-2 p-2 text-blue-400"><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>
      <li class="m-2 p-2 text-blue-400"><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>
      <li class="m-2 p-2 text-blue-400"><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>
      <li class="m-2 p-2 text-blue-400"><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>
    </ul>
    <h3 class="p-4 text-teal-500 font-bold text-xl bg-lime-50 rounded-full shadow-lg border-teal-500 border w-80">Ecosystem</h3>
    <ul class="bg-slate-100 border w-full m-4 rounded-lg shadow-lg">
      <li class="m-2 p-2 text-blue-400"><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li class="m-2 p-2 text-blue-400"><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li class="m-2 p-2 text-blue-400"><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li class="m-2 p-2 text-blue-400"><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li class="m-2 p-2 text-blue-400"><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

